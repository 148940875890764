<template>
  <page-header title="Мой профиль" />

  <q-card v-if="user !== null" class="user">
    <div class="user__header">
      <div class="user__avatar">
        <user-avatar :user="user" />
      </div>
      <div class="user__name">
        {{ user.name }}
      </div>
      <div class="user__name-edit">
        <q-btn @click="showChangeNameDialog" class="change-button">
          <q-icon class="change-button__icon">
            <img src="../../assets/icons/edit.svg" alt="Изменить имя">
          </q-icon>
          <span class="change-button__text">Изменить имя</span>
        </q-btn>
      </div>
    </div>

    <div v-if="user.email" class="user__field">
      <div class="user__field-key">
        Электронная почта
      </div>
      <div class="user__field-value">
        {{ user.email }}
      </div>
      <div class="user__field-edit">
        <q-btn @click="showChangeEmailDialog" class="change-button">
          <q-icon class="change-button__icon">
            <img src="../../assets/icons/edit.svg" alt="Изменить почту">
          </q-icon>
          <span class="change-button__text">Изменить почту</span>
        </q-btn>
      </div>
    </div>

    <div class="user__field">
      <div class="user__field-key">
        Пароль
      </div>
      <div class="user__field-value">
        <img src="../../assets/icons/password-dots.svg" alt="">
      </div>
      <div class="user__field-edit">
        <q-btn @click="showChangePasswordDialog" class="change-button">
          <q-icon class="change-button__icon">
            <img src="../../assets/icons/edit.svg" alt="Изменить пароль">
          </q-icon>
          <span class="change-button__text">Изменить пароль</span>
        </q-btn>
      </div>
    </div>

    <div v-if="user.role" class="user__field">
      <div class="user__field-key">
        Роль в системе
      </div>
      <div class="user__field-value">
        {{ user.role.title }}
      </div>
    </div>

    <div v-if="user.createdAt" class="user__field row">
      <div class="user__field-key">
        Дата регистрации
      </div>
      <div class="user__field-value" v-html="formatDate(user.createdAt)" />
    </div>

    <div v-if="user.visitedAt" class="user__field row">
      <div class="user__field-key">
        Дата последнего входа
      </div>
      <div class="user__field-value" v-html="formatDate(user.visitedAt)" />
    </div>
  </q-card>
</template>

<script>
import loading from '../../loading.js'
import {api as authApi} from '../../api/auth.js'
import {api as usersApi} from '../../api/users.js'
import PageHeader from '../../blocks/PageHeader.vue'
import UserAvatar from '../../blocks/UserAvatar.vue'
import ChangeNameDialog from '../../dialogs/profile/ChangeNameDialog.vue'
import ChangeEmailDialog from '../../dialogs/profile/ChangeEmailDialog.vue'
import ChangePasswordDialog from '../../dialogs/profile/ChangePasswordDialog.vue'

export default {
  components: {
    PageHeader,
    UserAvatar,
  },
  async beforeRouteEnter (to, from, next) {
    loading.start()
    const user = await usersApi.find(authApi.getCurrentUser().id)
    if (!user) {
      next(false)
      return
    }
    next(vm => { vm.user = user })
    loading.stop()
    loading.updateTitle(to.meta.title || '')
  },
  data() {
    return {
      user: null,
    }
  },
  methods: {
    async reload(user) {
      this.user = user
    },
    showChangeNameDialog() {
      this.$root
        .createDialog({
          component: ChangeNameDialog,
          componentProps: {
            user: this.user,
            hook: this.reload,
          },
        })
        .onOk(() => {
          this.$root.showSuccessMessage('Имя изменено.')
        })
    },
    showChangeEmailDialog() {
      this.$root
        .createDialog({
          component: ChangeEmailDialog,
          componentProps: {
            user: this.user,
            hook: this.reload,
          },
        })
        .onOk(() => {
          this.$root.showSuccessMessage('Электронная почта изменена.')
        })
    },
    showChangePasswordDialog() {
      this.$root
        .createDialog({
          component: ChangePasswordDialog,
          componentProps: {
            user: this.user,
            hook: this.reload,
          },
        })
        .onOk(() => {
          this.$root.showSuccessMessage('Пароль изменён.')
        })
    },
    formatDate(date) {
      const format = value => value < 10 ? `0${value}` : value
      let day = format(date.getDate())
      let month = format(date.getMonth() + 1)
      let year = date.getFullYear()
      let hours = format(date.getHours())
      let minutes = format(date.getMinutes())
      return `${day}.${month}.${year}&nbsp;&nbsp;${hours}:${minutes}`
    },
  },
}
</script>

<style lang="scss">
@import "~@/spa/styles/quasar.variables.scss";

.user {
  &__header {
    display: flex;
    min-height: 80px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px 20px 20px;
    @media (max-width: $breakpoint-xs-max) {
      padding: 20px 5px 20px 20px;
    }
  }

  &__avatar {
    margin-right: 24px;
    @media (max-width: $breakpoint-xs-max) {
      margin-right: 12px;
    }
  }

  &__name {
    flex: 1;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    font-size: 24px;
  }

  &__actions {
    .action {
      font-family: Raleway, sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: #2C7DDC;

      @media (max-width: $breakpoint-xs-max) {
        & {
          height: 34px;
          width: 34px;
          margin-left: 12px;
        }

        .action__text {
          display: none;
        }
      }
    }
  }

  &__field {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    border-top: 2px solid #F5F6FA;

    @media (max-width: $breakpoint-xs-max) {
      flex-wrap: wrap;
    }
  }

  &__field-key {
    padding: 22px 20px;
    min-height: 60px;
    flex: 0 0 200px;
    width: 200px;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);

    @media (max-width: $breakpoint-xs-max) {
      font-size: 11px;
      padding: 15px 20px 5px;
      flex-basis: calc(100% - 60px);
      min-height: auto;
    }
  }

  &__field-value {
    flex: 1;
    padding: 19px;
    line-height: 22px;
    @media (max-width: $breakpoint-xs-max) {
      padding: 5px 20px 15px;
      flex-basis: calc(100% - 60px);
    }
  }

  &__field-edit {
    padding: 10px 10px;
    font-weight: 500;
    @media (max-width: $breakpoint-xs-max) {
      width: 50px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .change-button {
    padding: 5px 6px;
    font-size: 14px;
    line-height: 16px;
    color: #2C7DDC;

    &__text {
      margin-left: 4px;
    }
    @media (max-width: 1000px) {
      &__text {
        display: none;
      }
    }
  }
}
</style>
