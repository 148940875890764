<template>
  <q-dialog ref="dialog" persistent @hide="hide">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
        <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/dialog/change-email.svg" class="dialog-header-icon" alt="">
        Изменить почту
      </div>

      <q-banner v-if="error" inline-actions class="text-white bg-red">{{ error }}</q-banner>

      <q-input
        v-model="form.email.value"
        label="Новая электронная почта"
        outlined
        required
        :error="form.email.error != null"
        :error-message="form.email.error"
        maxlength="200"
      />

      <q-form @submit.prevent="onSubmit">
        <q-input
          v-model="form.password.value"
          outlined
          required
          :type="form.password.hidden ? 'password' : 'text'"
          label="Текущий пароль"
          :error="form.password.error != null"
          :error-message="form.password.error"
          maxlength="200"
        >
          <template v-slot:append>
            <q-icon
              :name="form.password.hidden ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="form.password.hidden = !form.password.hidden"
            />
          </template>
        </q-input>

        <div class="dialog-actions">
          <q-btn label="Отменить" flat class="cancel-button" @click.prevent="onCancelClick" />
          <q-btn label="Сохранить" type="submit" color="primary" :loading="processing" />
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { api as usersApi } from '../../api/users.js'
import { api as authApi } from '../../api/auth.js'

export default {
  name: 'ChangeEmailDialog',
  props: {
    user: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
  },
  emits: ['ok'],
  data() {
    return {
      processing: false,
      error: null,

      form: {
        email: {
          value: this.user.email,
          error: null,
        },
        password: {
          value: null,
          hidden: true,
          error: null,
        },
      }
    }
  },
  methods: {
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    },
    onCancelClick() {
      this.hide()
    },
    onSubmit() {
      this.processing = true
      usersApi
        .changeUserEmail(this.user, this.form.email.value, this.form.password.value)
        .then(user => {
          const restoredUser = authApi.restoreUser()
          restoredUser.email = user.email
          authApi.storeUser(restoredUser)

          this
            .hook(user)
            .then(() => {
              this.$emit('ok', user)
              this.hide()
            })
        })
        .catch(errors => {
          this.processing = false
          if (Array.isArray(errors)) {
            errors.forEach(error => {
              switch (error.source) {
                case 'email':
                  this.form.email.error = error.title
                  break;
                case 'password_current':
                  this.form.password.error = error.title
                  break;
              }
            })
          }
        })
    },
  },
}
</script>
