<template>
  <q-dialog ref="dialog" persistent @hide="hide">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
        <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/dialog/change-password.svg" class="dialog-header-icon" alt="">
        Изменить пароль
      </div>

      <q-banner v-if="error" inline-actions class="text-white bg-red">{{ error }}</q-banner>

      <q-form @submit.prevent="onSubmit">
        <q-input
          v-model="form.newPassword.value"
          outlined
          required
          :type="form.newPassword.hidden ? 'password' : 'text'"
          label="Новый пароль"
          :error="form.newPassword.error != null"
          :error-message="form.newPassword.error"
          maxlength="200"
        >
          <template v-slot:append>
            <q-icon
              :name="form.newPassword.hidden ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="form.newPassword.hidden = !form.newPassword.hidden"
            />
          </template>
        </q-input>

        <q-input
          v-model="form.newPasswordRepeat.value"
          outlined
          required
          :type="form.newPassword.hidden ? 'password' : 'text'"
          label="Повторите новый пароль"
          :error="form.newPasswordRepeat.error != null"
          :error-message="form.newPasswordRepeat.error"
          maxlength="200"
        >
          <template v-slot:append>
            <q-icon
              :name="form.newPassword.hidden ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="form.newPassword.hidden = !form.newPassword.hidden"
            />
          </template>
        </q-input>

        <q-input
          v-model="form.password.value"
          outlined
          required
          :type="form.password.hidden ? 'password' : 'text'"
          label="Текущий пароль"
          :error="form.password.error != null"
          :error-message="form.password.error"
          maxlength="200"
        >
          <template v-slot:append>
            <q-icon
              :name="form.password.hidden ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="form.password.hidden = !form.password.hidden"
            />
          </template>
        </q-input>


        <div class="dialog-actions">
          <q-btn label="Отменить" flat class="cancel-button" @click.prevent="onCancelClick" />
          <q-btn label="Сохранить" type="submit" color="primary" :loading="processing" />
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { api as usersApi } from '../../api/users.js'

export default {
  name: 'ChangePasswordDialog',
  props: {
    user: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
  },
  emits: ['ok'],
  data() {
    return {
      processing: false,
      error: null,

      form: {
        newPassword: {
          value: '',
          hidden: true,
          error: null,
        },
        newPasswordRepeat: {
          value: '',
          error: null,
        },
        password: {
          value: null,
          hidden: true,
          error: null,
        },
      }
    }
  },
  methods: {
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    },
    onCancelClick () {
      this.hide()
    },
    onSubmit() {
      this.form.newPassword.error = null
      this.form.newPasswordRepeat.error = null
      this.form.password.error = null

      let hasErrors = false
      if (this.form.newPassword.value !== this.form.newPasswordRepeat.value) {
        this.form.newPassword.error = 'Пароли не совпадают.'
        this.form.newPasswordRepeat.error = 'Пароли не совпадают.'
        hasErrors = true
      }

      if (!hasErrors) {
        this.processing = true
        usersApi
          .changeUserPassword(this.user, this.form.newPassword.value, this.form.password.value)
          .then(tag => {
            this
              .hook(tag)
              .then(() => {
                this.$emit('ok', tag)
                this.hide()
              })
          })
          .catch(errors => {
            this.processing = false
            if (Array.isArray(errors)) {
              errors.forEach(error => {
                switch (error.source) {
                  case 'password':
                    this.form.password.error = error.title
                    break;
                  case 'password_current':
                    this.form.password.error = error.title
                    break;
                }
              })
            }
          })
      }
    },
  },
}
</script>
